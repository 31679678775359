export enum EQuestionType {
  'singleChoice' = 'singleChoice',
  'multipleChoice' = 'multipleChoice',
  'slider' = 'slider',
  'textarea' = 'textarea',
  'markdown' = 'markdown',
  'medications' = 'medications',
  'signature' = 'signature',
  'date' = 'date',
  'period' = 'period',
  'generalPainArea' = 'generalPainArea',
  'nrs' = 'nrs',
  'detailedPainArea' = 'detailedPainArea',
  'selectPainArea' = 'selectPainArea',
  'consentForm' = 'consentForm',
  'list' = 'list',
  'tagEditor' = 'tagEditor',
  'textOrSkip' = 'textOrSkip',
}

export enum EQuestionTypeTitle {
  'singleChoice' = 'Single choice',
  'multipleChoice' = 'Multiple choice',
  'slider' = 'Slider',
  'textarea' = 'Textarea',
  'markdown' = 'Markdown',
  'medications' = 'Medications',
  'signature' = 'Signature',
  'date' = 'Date',
  'period' = 'Period',
  'generalPainArea' = 'General Pain Area',
  'nrs' = 'NRS',
  'detailedPainArea' = 'Detailed Pain Area',
  'selectPainArea' = 'Select Pain Area',
  'consentForm' = 'Consent Form',
  'list' = 'List',
  'tagEditor' = 'Tag Editor',
  'textOrSkip' = 'Text-or-skip',
}

export const enum ERelationConditionType {
  'IS' = 'IS',
  'IS_NOT' = 'IS_NOT',
  'FALSE' = 'FALSE',
  'TRUE' = 'TRUE',
  'EMPTY' = 'EMPTY',
  'NOT_EMPTY' = 'NOT_EMPTY',
}

export const enum EQuestionCondition {
  Always = 'ALWAYS',
  When = 'WHEN',
}

export const enum EQuestionOrientation {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

export const enum EQuestionFormat {
  Radio = 'radio',
  Button = 'button',
  Checkbox = 'checkbox',
}

export const enum EQuestionForm {
  Label = 'label',
  Type = 'type',
  Options = 'options',
  TranslatedOptions = 'translatedOptions',
  Orientation = 'orientation',
  Multiselect = 'multiselect',
  Min = 'min',
  Max = 'max',
  Step = 'step',
  Scoring = 'scoring',
  AddOn = 'addOn',
  SkipOption = 'skipOption',
  MinLabel = 'minLabel',
  MaxLabel = 'maxLabel',
  SubText = 'subText',
  ShowFaceImages = 'showFaceImages',
  ShowDescription = 'showDescription',
  Palette = 'palette',
  OtherOptionTitle = 'otherOptionTitle',
  AddOther = 'addOther',
  DefaultPeriod = 'defaultPeriod',
  SelectedDefaultPeriod = 'selectedDefaultPeriod',
  CustomMarkdown = 'customMarkdown',
  UseCustomMarkdown = 'useCustomMarkdown',
  RelationType = 'relationType',
  RelationParent = 'relationParent',
  RelationCondition = 'relationCondition',
  RelationValue = 'relationValue',
  Key = 'key',
  ConsentType = 'consentType',
  ListItemTitle = 'listItemTitle',
  ListItemPlaceholder = 'listItemPlaceholder',
  NRSDescriptorItemValue = 'NRSDescriptorItemValue',
  NRSDescriptorItemClassification = 'classification',
  NRSDescriptorItemDescription = 'descriptor',
  NRSMinValueDescriptor = 'minValueDescriptor',
  NRSMaxValueDescriptor = 'maxValueDescriptor',
  Placeholder = 'placeholder',
  CheckboxText = 'checkboxText',
  IsCurrentValueHidden = 'isCurrentValueHidden',
  ValuePlaceholder = 'valuePlaceholder',
  PeriodPlaceholder = 'periodPlaceholder',
  NoneOfTheAbove = 'noneOfTheAbove',
  NoneOfTheAboveOptionTitle = 'noneOfTheAboveOptionTitle',
}

export const enum EReorderType {
  Up = 'up',
  Down = 'down',
}

export const enum EPalette {
  Monochrome = 'monochrome',
  Multicolored = 'multicolored',
}

export const enum ESkipOption {
  None = 'NONE',
  WithoutConfirmation = 'WITHOUT_CONFIRMATION',
  WithConfirmation = 'WITH_CONFIRMATION',
}

export const enum ERelationType {
  Visibility = 'visibility',
  Placeholder = 'placeholder',
}

export const enum EQuestionViewElementType {
  Control = 'control',
  Logo = 'logo',
  Copyright = 'copyright',
  Style = 'style',
  Range = 'range',
  NRS = 'nrs',
}

export const enum EQuestionPossibleType {
  UniqueAppearance = 'uniqueAppearance',
  Options = 'options',
  OtherOption = 'otherOption',
  NoneOfTheAboveOption = 'noneOfTheAboveOption',
}
export const enum ETextOrSkipQuestionInputType {
  Input = 'input',
  Textarea = 'textarea',
}
export interface IViewElementParams {
  key: string;
  value: { value: string | boolean };
}
export interface IOptionChange {
  id: string;
  value: { value?: string; score?: string };
}
