import React, { FC, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Checkbox, InputNumber } from 'antd';
import { ETextOrSkipQuestionInputType } from 'common/models/questions';
import { IQuestionViewTextOrSkip, QuestionWithTranslation } from 'entities/Questions/questions.model';

interface IComponentProps {
  question: QuestionWithTranslation;
  isAnotherLang: boolean;
}

export const TextOrSkip: FC<IComponentProps> = ({ question, isAnotherLang }) => {
  const [value, setValue] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);

  const placeholder: string | undefined =
    isAnotherLang && question.translations?.view?.placeholder
      ? question.translations.view.placeholder
      : (question.view as IQuestionViewTextOrSkip).placeholder;

  return (
    <div className="questions__form_common-text-or-skip-container">
      {(question.view as IQuestionViewTextOrSkip).inputType === ETextOrSkipQuestionInputType.Textarea ? (
        <TextArea
          className="questions__form_common-textarea"
          rows={4}
          placeholder={placeholder}
          bordered
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={checked}
        />
      ) : (
        <InputNumber
          style={{ width: '100%' }}
          placeholder={placeholder}
          bordered
          value={value}
          onChange={(input) => {
            const result = input !== null ? input.toString() : '';

            if (result.length <= 3) {
              setValue(result);
            }
          }}
          disabled={checked}
        />
      )}

      <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} disabled={!!value.length}>
        {isAnotherLang && question?.translations?.view?.checkboxText
          ? question.translations.view.checkboxText
          : (question.view as IQuestionViewTextOrSkip).checkboxText}
      </Checkbox>
    </div>
  );
};
